import { Button, Flex, Heading, Text, Box } from "@chakra-ui/react";
import Image from "next/image";

function NoFoundPage() {
   return (
      <Flex
         w="100vw"
         h="100vh"
         alignItems="center"
         justifyContent="center"
         flexDir="column"
      >
         <Image
            src="/av-logo.png"
            alt="Alta Vista Investimentos"
            width={300}
            height={208}
         />
         <Heading as="h5">Página não encontrada</Heading>
         <Box maxWidth="460px" my="6">
            <Text textAlign="center">
               Não encontramos a página que procura, tente procurar por ela
               novamente na página inicial.
            </Text>
         </Box>
         <Button as="a" href="/" variant="outline">
            Página inicial
         </Button>
      </Flex>
   );
}

export default NoFoundPage;
